import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import styles from "./carer-grid.module.scss";

export default function CarerGrid() {
  return (
    <StaticQuery
      query={graphql`
        query MembersQuery {
          allContentfulCaregiver(
            sort: { order: ASC, fields: memberSince }
            filter: { status: { eq: "active" } }
          ) {
            totalCount
            nodes {
              slug
              fullName
              carerImage {
                fluid(
                  cropFocus: FACE
                  resizingBehavior: FILL
                  quality: 100
                  maxWidth: 300
                  maxHeight: 300
                ) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className={styles.grid}>
          {data.allContentfulCaregiver.nodes.map(
            ({ slug, fullName, carerImage }) => (
              <Link to={`/carers`} key={`carer-grid_${slug}`}>
                <Img
                  className={styles.image}
                  alt={fullName}
                  fluid={carerImage.fluid}
                />
              </Link>
            )
          )}
        </div>
      )}
    />
  );
}

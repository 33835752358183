import React from "react";
import { Link, graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CarerPreview from "../components/carer-preview";
import Button from "../system/button";
import Img from "gatsby-image"

import CarerGrid from "../components/carer-grid";

import carerStyles from "./carers.module.scss";

class RootIndex extends React.Component {
  render() {
    return (
        <Layout location={this.props.location}>
        <section>

        <h1>I'm a Heading (1)</h1>
        <h2>I'm a Heading (2)</h2>
        <h3>I'm a Heading (3)</h3>
        <h4>I'm a Heading (4)</h4>
        <h5>I'm a Heading (5)</h5>
        </section>
        <section>
            <h2>I'm a Heading (2)</h2>
            <p>I'm a paragraph</p>
            <p className="large">I'm a large paragraph</p>
        </section>
      </Layout>
    )
  }
}

export default RootIndex;
